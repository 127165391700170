* {
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

body {
  font-family: "Archivo Black", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff9f2;
  transition: 0.4s ease-in-out;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: 0.4s ease-in-out;
  overflow-x: hidden;
}


.section {
  font-size: 2.5rem;
  text-align: left;
  margin: 0 auto;
  transition: color 0.5s ease-in-out visibility 0.5s;
  color: #1d1e1f;
  white-space: pre-wrap;
  width: auto;

  h1 {
    margin: -1rem 0 -1.5rem 0;
    font-family: "Staatliches";
    font-weight: 800;
    line-height: 8rem;  
  }

  span {
    display: block;
    font-family: "Archivo";
    font-size: 1.5rem;
    font-weight: 500;
  }

  a {
    color: #EF6F6C;
    text-decoration: none;
  }

  @media (max-width: 1025px) {
    font-size: 2.1rem;

    span{
      font-size: 1.3rem;
    }
  }

  @media (max-width: 769px) {

    h1{
      font-size: 5rem;
      line-height: 5rem;
      margin-bottom: 0.3rem;
    }
    span{
      font-size: 1.3rem;
    }
  }

  @media (max-width: 500px) {
    text-align: right;
    letter-spacing: -0.01em;
    word-spacing: -0.01em;

    h1{
      font-size: 3.5rem;
      line-height: 3.5rem;
      margin-bottom: 0.8rem;
      letter-spacing: -0.02em;

    }

    span {
      font-size: 1rem;

    }

  }
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}

.react-grid-item{
  display: flex;
}

.portfolio-item-container{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  border-radius: 2px;
  transition: 0.3s ease-out;
}

.portfolio-item-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 
  
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
  
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: 0.3s ease-out;

}

.portfolio-item-container img {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: 0.3s ease-out;
}

.portfolio-item-container:hover {
  box-shadow: 4px 4px 0px 0px #A72331;
  border-radius: 0px;
  transform: translate(-4rem, -4rem) scale(1.02);
  transition: 0.3s;
  padding: 0px 0px;
}

.highlight-bg{
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #00000090;
  z-index: 9;
  transition:visibility 0.2s linear,opacity 0.2s linear;

  @media (max-width: 500px){
    display: flex;
  }
}

.highlight{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: 80vh;
  transform: translate(-50%,-50%);
  overflow: hidden;
  box-shadow: 5px 5px 0px 0px #A72331;
  transition: opacity 0.5s ease-in;

  @media (max-width: 769px) {
    transform: translate(-50%,-50%);
    height: 90vw;
    width: 90vw
  }

  @media (max-width: 461px) {
    transform: translate(-50%,-70%);
    height: 90vw;
    width: 90vw
  }
}

.highlight img{
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transition: opacity 0.5s ease-out 1s;
  transform: translate(-50%,-50%);
}

.highlight video{
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.3s ease-out;
  transform: translate(-50%,-50%);
  
}

.highlight-bg svg{
  fill: #fff9f2;
  z-index: 100;
}

.arrow-button{
  position: absolute;
  bottom: 70vh;
  font-size: 4rem;


  @media (max-width: 500px){
    bottom: 75vh;
    font-size: 4rem;
  }
}

.exit-button{
  position: absolute;
  top: 2vh;
  right: 1.5vw;
  color: #fff9f2;
  font-size: 2rem;
  z-index: 1000;

  @media (max-width: 500px){
    top: 2vh;
    right: 4vw;
  }
}

.mini-nav{
  bottom: 4vh;
  position: absolute;
  display: flex;
  flex-direction: row;
  font-size: 0.5rem;
  width: 100%;
  justify-content: center;
  z-index: 1001;
  @media (max-width: 500px){

  }

  svg{
    position: static;
    margin: 0px 2px;
  }
}

.title-card{
  position: fixed;
  top: 80%;
  left: 62%;
  width: 30%;
  height: 20%;
  color: #1e1e1f;
  background-color: #fff9f2;
  padding: 10px;
  z-index:100;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 0px 0px #A72331;


  .title{
    font-size: 2rem;
    margin: 0px 0px 8px 0px;
  }

  .description{
    font-family: "Archivo";
    font-size: 1rem;
  }

  @media (max-width: 1025px){
    top: 80%;
    left: 62%;
    width: 30%;
    height: 25%;
  }

  @media (max-width: 769px) {
    top: 80%;
    left: 62%;
    width: 30%;
    height: 20%;
  }

  @media (max-width: 500px) {
    top: 68%;
    width: 90vw;
    left: 5vw;
    height: 20%;
  }

  // @media (max-width: 391px) {
  //   top: 67%;
  //   width: 90vw;
  //   left: 5vw;
  //   height: 15%;
  // }

  // @media (max-width: 321px) {
  //   top: 62%;
  //   width: 90vw;
  //   left: 5vw;
  //   height: 15%;
  // }
}

.link {
  position: absolute;
  font-size: 0.9rem;
  transition: 0.3s cubic-bezier( 0.79, 0.33, 0.5, 1.4 );
  background: #fff9f2;
  height: 2rem;
  width: 100px;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px){
    right: 20px
  }
}

.link:hover {
  transform: translate(-4px, -4px);
  box-shadow: 5px 5px 0px 0px #fff9f2;
  background-color:  #A72331;
  color: #fff9f2;
}